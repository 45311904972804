import React, {useEffect, useState} from 'react'
import {useSnackbar} from 'notistack'
import {Box, CircularProgress, List} from '@mui/material'
import {useAuth} from 'src/contexts/Auth'
import {LanguageEnum} from 'src/settings/type'

import logo from 'src/components/shl/Test/assets/logo.png'
import ExaminToEmpType from 'src/entities/ExaminToEmp'
import config from 'src/config'
import TestItem from 'src/components/shl/Test/TestItem'

export default function TestList() {
  const [testList, setTestList] = useState<ExaminToEmpType[]>([])

  const [selectedTestUrl, setSelectedTestUrl] = useState<string>()
  const [loading, setLoading] = useState<boolean>(true)
  const {fetchWithUser, employee} = useAuth()
  const {enqueueSnackbar} = useSnackbar()

  useEffect(() => {
    if (!employee) return
    fetchWithUser(
      `${config.apiUrl}/Examination/GetExaminationListByEmpId?empId=${employee.employeeID}&isActiveEmp=true&isBackoffice=false`
    )
      .then(res => res.json())
      .then((data: ExaminToEmpType[]) => {
        setTestList(data)
        if (!data.length)
          return enqueueSnackbar(
            'There are still no open tests for this cycle',
            {variant: 'warning'}
          )
        setSelectedTestUrl(data[0].examinationUrl)
        setLoading(false)
      })
      .catch(e => {
        enqueueSnackbar(
          e.status === 404
            ? 'You are not active in the program, you do not have permission to enter'
            : 'Error, Please try again',
          {
            variant: e.status === 404 ? 'warning' : 'error'
          }
        )
      })
  }, [fetchWithUser, enqueueSnackbar, employee])

  return (
    <Box height="100%" style={{overflow: 'hidden'}}>
      {loading ? (
        <CircularProgress
          size={48}
          sx={{
            color: 'primary.main',
            position: 'absolute',
            top: '65%',
            left: '50%',
            marginTop: -1.5,
            marginLeft: -1.5
          }}
        />
      ) : (
        testList && (
          <Box
            height="100%"
            sx={{
              direction:
                testList[0].examinationCycleLanguageId === LanguageEnum.English
                  ? 'ltr'
                  : 'rtl'
            }}
          >
            <iframe
              title="Iframe Test"
              src={selectedTestUrl}
              style={{
                border: 'none',
                width: 'calc(100% - 250px)',
                height: '99.1%',
                marginInlineStart: 350,
                overflowY: 'auto'
              }}
            />
            <List
              sx={{
                height: '100vh',
                width: 350,
                bgcolor: 'background.paper',
                position: 'absolute',
                top: 0,
                px: 2,
                overflowY: 'auto'
              }}
            >
              <img
                alt="logo"
                src={logo}
                height="107px"
                style={{paddingBottom: 32, margin: 'auto'}}
              />

              {testList?.map((item, index) => (
                <TestItem
                  key={index}
                  index={index}
                  url={item.examinationUrl}
                  name={`Test ${item.examinationOrder}`}
                  raffleTicket={item.loteryTicketsNumber}
                  isWinnersLottery={item.isWinnerLotery}
                  submissionStatus={item.submissionStatus}
                  setSelectedTestUrl={setSelectedTestUrl}
                  selectedTestUrl={selectedTestUrl}
                />
              ))}
            </List>
          </Box>
        )
      )}
    </Box>
  )
}
