import React from 'react'
import {Box, styled, Typography} from '@mui/material'
import LotteryWrapper from 'src/components/shl/lottery/LotteryWrapper'
import 'src/components/shl/lottery/rfl.css'

const TopTextStyle = styled(Typography)(() => ({
  fontWeight: '700',
  color: '#fff',
  textAlign: 'center',
  display: 'block'
}))

export default function BlockedPage() {
  return (
    <Box bgcolor="primary.main" height="100%">
      <LotteryWrapper>
        <Box
          className="rafHead"
          textAlign="center"
          position="static"
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'space-around',
            height: 'calc(50% - 40px)'
          }}
          m="auto"
          mb="40px"
          pt="30px"
          py="40px"
          minWidth={300}
          width="63.33%"
          maxWidth={666.66}
          minHeight={265}
        >
          <TopTextStyle>
            <Typography component={'span'} pt="10px" fontSize="1.75em">
              You are not part of the SHL program and can't join the raffles.{' '}
              <br />
              You are welcome to register for the upcoming 15th cycle <a href="citybook.co.il/15th-cycle/welcome-shl">here</a>.
            </Typography>
          </TopTextStyle>
        </Box>
      </LotteryWrapper>
    </Box>
  )
}
