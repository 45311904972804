import React, {useEffect} from 'react'
import {Box, Button, Link, styled, Typography} from '@mui/material'
import {useAuth} from 'src/contexts/AuthWithEmail'
import CycleWrapper from 'src/components/shl/cycle/CycleWrapper'
import 'src/components/shl/cycle/cycle.css'

interface Props {
  isEnableUpdate: boolean
  onClose: () => void
}

const TopTextStyle = styled(Typography)(() => ({
  fontWeight: '700',
  color: '#fff',
  textAlign: 'center',
  display: 'block'
}))

export default function CycleSuccessPage({isEnableUpdate, onClose}: Props) {
  const {logout} = useAuth()
  useEffect(() => {
    if (!isEnableUpdate) return
    const interval = setInterval(logout, 10000)
    return () => clearInterval(interval)
  }, [logout, isEnableUpdate])

  return (
    <Box bgcolor="primary.main" height="100%">
      <CycleWrapper>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'space-around',
            height: 'calc(50% - 15px)',
            textAlign: 'center',
            position: 'static',
            m: 'auto',
            mb: '15px',
            pt: '30px',
            py: '15px',
            minWidth: 450,
            width: '95%',
            maxWidth: 1000,
            minHeight: 265
          }}
        >
          <TopTextStyle>
            <Typography component={'span'} pt={1.25} fontSize="2em" mb={9}>
              Thank you!
            </Typography>
          </TopTextStyle>
          <TopTextStyle>
            <Typography component={'span'} pt={1.25} fontSize="1.5em" mb={9}>
              Your choice has been submitted successfully.
            </Typography>
          </TopTextStyle>
         
          <TopTextStyle>
            <Typography component={'span'} display="block" fontWeight={400}>
              {isEnableUpdate ? (
                <>
                  To change your selection please {' '}
                  <Button
                    component={'span'}
                    style={{color: '#fff'}}
                    onClick={onClose}
                  >
                    click here
                  </Button>
                </>
              ) : (
                <>
                  For any changes or questions contact Ricki Schloss
                  <br />
                  at
                  <Link
                    href="mailto:rschloss@citybook.co.il"
                    sx={{cursor: 'pointer'}}
                    target="_blank"
                    color="#fff"
                  >
                    {' '}
                    rschloss@citybook.co.il
                  </Link>
                </>
              )}
            </Typography>
          </TopTextStyle>
         
        </Box>
      </CycleWrapper>
    </Box>
  )
}
